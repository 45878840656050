
const blogLayout = function(){
    const utils = document.querySelector('.blog-utilbar');
    const defaultLayout = 'posts-grid';

    if(utils){
        const grid = utils.querySelector('.grid');
        const list = utils.querySelector('.list');
        const container = document.querySelector('main.blog');

        container.classList.add(defaultLayout);

        grid.addEventListener('click', function(e){
            e.preventDefault();

            this.classList.add('active');
            list.classList.remove('active');
            container.classList.add('posts-grid');
            container.classList.remove('posts-list');
        });

        list.addEventListener('click', function(e){
            e.preventDefault();
            
            this.classList.add('active');
            grid.classList.remove('active');
            container.classList.add('posts-list');
            container.classList.remove('posts-grid');
        });
    }

}

const faqToggle = function(){
    let faqs = document.querySelector('.faqs');

    if(faqs){
        let questions = faqs.querySelectorAll('.faq-question');

        questions.forEach(function(question){
            question.addEventListener('click', function(){
                let node = question.parentElement;
                let answer = node.querySelector('.faq-answer');
                let answerHeight = answer.scrollHeight + 20;

                if(node.classList.contains('active')){
                    node.classList.remove('active');
                    answer.style.maxHeight = 0;
                }else{
                    node.classList.add('active');
                    answer.style.maxHeight = answerHeight+'px';
                }
            });
        });
    }
}

const mobileMenuToggle = function(){
    let menuUtil = document.querySelector('.util-mobile-menu');
    let menu = document.querySelector('.navbar nav');

    menuUtil.addEventListener('click', function(e){
        e.preventDefault();

        if(menu.classList.contains('active')){
            menu.classList.remove('active');
            document.querySelector('body').classList.remove('mobile-menu-active');
        }else{
            menu.classList.add('active');
            document.querySelector('body').classList.add('mobile-menu-active');
        }

    })
}

const mobileExpand = function(){
    let mql = window.matchMedia('(max-width: 1023px)');
    let menu = document.querySelector('.navbar nav .menu');

    if(mql.matches){
        let parents = menu.querySelectorAll('.menu-item-has-children');

        parents.forEach(function(parent){
            parent.querySelector(':scope > a').addEventListener('click', function(e){
                e.preventDefault();
                let submenu = parent.querySelector('ul');
                let submenuHeight = submenu.scrollHeight;

                if(parent.classList.contains('active')){
                    parent.classList.remove('active');
                    submenu.style.maxHeight = 0;
                }else{
                    parent.classList.add('active');
                    submenu.style.maxHeight = '300px';
                }
            });
        });
    }
}

const nestedSubMenu = function(){
    let menuItems = document.querySelectorAll('.navbar .sub-menu li.menu-item-has-children');
    let mql = window.matchMedia('(min-width: 1024px)');

    if(mql.matches){
        menuItems.forEach(function(item){
            item.querySelector(':scope > a').addEventListener('click', function(e){
                e.preventDefault();
                let submenu = item.querySelector('ul');
                let submenuHeight = submenu.scrollHeight;

                if(item.classList.contains('active')){
                    item.classList.remove('active');
                    submenu.style.maxHeight = 0;
                }else{
                    item.classList.add('active');
                    submenu.style.maxHeight = submenuHeight+'px';
                }
            });
        });
    }
}

const blogUtilToggle = function(){
    let blogUtil = document.querySelector('.tax-nav');

    if(blogUtil){
        let toggle = blogUtil.querySelector('nav .label');
        let linkList = blogUtil.querySelector('nav .links');

        toggle.addEventListener('click', function(e){
            e.preventDefault();

            if(blogUtil.classList.contains('expanded')){
                blogUtil.classList.remove('expanded');
            }else{
                blogUtil.classList.add('expanded');
            }
        });
    }
}

const isItDark = function(imageSrc,callback) {
    var fuzzy = 0.1;
    var img = document.createElement("img");
    img.src = imageSrc;
    img.style.display = "none";
    document.body.appendChild(img);

    img.onload = function() {
        // create canvas
        var canvas = document.createElement("canvas");
        canvas.width = this.width;
        canvas.height = this.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(this,0,0);

        var imageData = ctx.getImageData(0,0,canvas.width,canvas.height);
        var data = imageData.data;
        var r,g,b, max_rgb;
        var light = 0, dark = 0;

        for(var x = 0, len = data.length; x < len; x+=4) {
            r = data[x];
            g = data[x+1];
            b = data[x+2];

            max_rgb = Math.max(Math.max(r, g), b);
            if (max_rgb < 128)
                dark++;
            else
                light++;
        }

        var dl_diff = ((light - dark) / (this.width*this.height));
        if (dl_diff + fuzzy < 0)
            callback(true); /* Dark. */
        else
            callback(false);  /* Not dark. */
    }
}

const updateNavColor = function(){
    const pageHero = document.querySelector('.page-hero');

    if(pageHero){
        const heroImg = pageHero.querySelector('figure img').src;
        isItDark(heroImg, (invert) => {
            if(invert){
                document.querySelector('.navbar').classList.add('invert-nav');
            }
        });
    }
}

const debounce = function(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

const mobileNavFixed = function(){
    var scrollPosY = window.pageYOffset | document.body.scrollTop;

    if(scrollPosY > 0){
        document.querySelector('body').classList.add('navbar-sticky');
    }else{
        document.querySelector('body').classList.remove('navbar-sticky');
    }
};

window.addEventListener('scroll', mobileNavFixed);

updateNavColor();
blogLayout();
blogUtilToggle();
faqToggle();
mobileMenuToggle();

mobileExpand();
nestedSubMenu();

$(document).ready(function(){
    $('.home .slides').slick({ 
		dots:true, 
        adaptiveHeight:true,
        autoplay:true,
        autoplaySpeed:7000
    });
    
    $('.testimonials').slick({
        dots:true,
        adaptiveHeight:true,
    });

    $('.slider .slides').slick({ 
		dots:true, 
        adaptiveHeight:true,
        fade:true
    });

    $('.gallery-slides').slick({
        dots:false,
        variableWidth: true,
        lazyLoad: 'progressive',
        infinite: false,
        responsive: [
            {
              breakpoint: 767,
              settings: {
                variableWidth: false,
                adaptiveHeight:true
              }
            }
        ]
    })

    $('.gallery-slides').on('afterChange', function(event, slick, currentSlide, nextSlide){
        var current = $(slick.$slides.get(currentSlide)).attr('data-slick-index');
        current++;
        if(current < 10){
            current = '0'+current;
        }

        $('.gallery-slider .pager .current').html(current);
        //console.log($(slick.$slides.get(currentSlide)).attr('data-slick-index'));
    });
});